import * as Helpers from '../helpers/responsive_helpers'
import Component from './component'

export default class VideoBackground extends Component {
  videoElement: any
  lastSize: number
  constructor(params?, element?) {
    super()
    this.element = element
    this.videoElement = this.element.get(0)
  }
  onDetach() {
    // .
  }
  onAttach() {

    this.element.on('inview', (event, isInView) => {
      if (isInView) {
        this.attachVideo()
        this.videoElement.play()
      } else {
        this.detachVideo()
        this.videoElement.pause()
      }
    })
    window.addEventListener('orientationchange', () => {
      this.detachVideo()
      setTimeout(() => {
        this.attachVideo()
      }, 100)
    })
  }
  attachVideo() {
    const sourceWebM = $('<source />')
    const sourceMp4 = $('<source />')
    const sourceMobileMp4 = $('<source />')
    const fileName = this.element.data(Helpers.isMobile() ? 'mobile' : 'desktop');

    sourceWebM.attr('src', `videos/${fileName}.webm`)
    sourceWebM.attr('type', 'video/webm')
    sourceMp4.attr('src', `videos/${fileName}.mp4`)
    sourceMp4.attr('type', 'video/mp4')
    sourceMobileMp4.attr('src', `videos/${fileName}-mobile.mp4`)
    sourceMobileMp4.attr('type', 'video/mp4')
    this.element.attr('poster', `videos/${fileName}-poster.jpg`)
    this.element.append(sourceWebM)
    this.element.append(sourceMp4)
    this.element.append(sourceMobileMp4)
    this.videoElement.load()
    this.videoElement.play()
  }
  detachVideo() {
    this.videoElement.pause()
    this.element.html('')
  }
}
